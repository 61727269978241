<template>
  <div class="wrapper">
    <div class="main main-raised">
      <!-- Features 2 -->
      <div class="section">
        <div class="container">
          <div class="md-layout"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
            >
              <h2 class="title text-center lcheader">
                The Future
              </h2>
            </div>
          </div>
          <div class="md-layout-item md-size-10 md-small-size-20 mx-auto text-center"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <Arrow/>
          </div>
          <div class="md-layout"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <p class="lctext">
                At <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity</a></b>, we are excited to reach our 10-year anniversary, 
                but we recognize this is only a small milestone as we continue 
                our journey toward building an economy that works for Good. 
                Help us make the next 10 years even more impactful by joining us in implementing a holistic approach that works to further the overall well-being of all communities and especially those impacted by historical racial disparities. 
              </p>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="container">
          <div class="md-layout"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <div class="iframe-container">
                <iframe id="video1" src="https://www.youtube.com/embed/uIW455gCQw4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <br>
      </div>
      <!-- end Features 2 -->


      <!-- Diagram -->
      <div class="section section-features-3">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100"
                data-aos="fade-up"
                data-aos-easing="mk-easing"
                data-aos-duration="750">
              <!-- Some <br /> tags to push the text to align with the image, you can remove it if you have more text on the right side :-) -->
              <a  href="https://loveyourcity.us/" target="_blank">
              <h2 class="title lcheader lcdsec">
                Our approach:<br>
                place-based Impact
              </h2>
              </a>
              <p class="lctext lcdsec">
                Our collective impact model includes the development of 1) a Community Advisory 
                Board that represents the most important impact needs facing your community, 
                2) defining partners who offer technical assistance or investment in these 
                areas (service providers), and 3) engaging outside organizations to participate 
                in building an Economy that works for Good through the <b><a href="https://loveyourcity.us/" target="_blank" class="text-success">Love Your City™</a></b> platform 
                and other tools. This is a general model that is tailored in every community we serve.
              </p>
              <!--
              <info-areas
                info-horizontal
                icon-color="success"
                icon="place"
              >
                <h4
                  slot="title"
                  class="info-title lc1"
                >
                  Place Based
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p class="lctext">
                    Built and validated with every community where we go.
                  </p>
                </div>
              </info-areas>
              <info-areas
                info-horizontal
                icon-color="info"
                icon="search"
              >
                <h4
                  slot="title"
                  class="info-title lc2"
                >
                  Transparent
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p class="lctext">
                    Designed as a meeting point for organizations and communities to have honest conversations.
                  </p>
                </div>
              </info-areas>
              <info-areas
                info-horizontal
                icon-color="danger"
                icon="groups"
              >
                <h4
                  slot="title"
                  class="info-title lc3"
                >
                  Collective
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p class="lctext">
                    The hub where communities gather to make impact happen.
                  </p>
                </div>
              </info-areas>
              -->
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100"
                data-aos="fade-left"
                data-aos-easing="mk-easing"
                data-aos-duration="750">
              <div class="container">
                <a href="https://mylifecity.com/" target="_blank">
                  <Loopdiagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Diagram -->
      <!-- LYC -->
      <div class="section section-features-3">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100"
                data-aos="fade-right"
                data-aos-easing="mk-easing"
                data-aos-duration="750">
              <div class="container">
                <a href="https://loveyourcity.us/" target="_blank">
                  <Futurecomp />
                </a>
              </div>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100"
                data-aos="fade-up"
                data-aos-easing="mk-easing"
                data-aos-duration="750">
              <!-- Some <br /> tags to push the text to align with the image, you can remove it if you have more text on the right side :-) -->
              <a  href="https://loveyourcity.us/" target="_blank">
              <h2 class="title lcheader">
                Love Your City:<br>
                Your local Impact Hub
              </h2>
              </a>
              <p class="lctext">
                The <b><a href="https://loveyourcity.us/" target="_blank" class="text-success">Love Your City™</a></b> platform combines software and 
                social networks together to form a local impact hub for communities interested in accelerating equity 
                and sustainability. By leveraging local experts who are already invested in this work through a 
                transparent software tool that allows organizations to share their impact, communities can set 
                Let’s build a system that works for Good!Learn more at <b><a href="https://loveyourcity.us/" target="_blank" class="text-success">www.loveyourcity.us.</a></b>
                goals, measure milestones, and incentivize action!<br>
                <br>
                Learn more at <b><a href="https://loveyourcity.us/" target="_blank" class="text-success">www.loveyourcity.us.</a></b>
              </p>
              <!--
              <info-areas
                info-horizontal
                icon-color="success"
                icon="place"
              >
                <h4
                  slot="title"
                  class="info-title lc1"
                >
                  Place Based
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p class="lctext">
                    Built and validated with every community where we go.
                  </p>
                </div>
              </info-areas>
              <info-areas
                info-horizontal
                icon-color="info"
                icon="search"
              >
                <h4
                  slot="title"
                  class="info-title lc2"
                >
                  Transparent
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p class="lctext">
                    Designed as a meeting point for organizations and communities to have honest conversations.
                  </p>
                </div>
              </info-areas>
              <info-areas
                info-horizontal
                icon-color="danger"
                icon="groups"
              >
                <h4
                  slot="title"
                  class="info-title lc3"
                >
                  Collective
                </h4>
                <div
                  slot="content"
                  class="description"
                >
                  <p class="lctext">
                    The hub where communities gather to make impact happen.
                  </p>
                </div>
              </info-areas>
              -->
            </div>
          </div>
        </div>
      </div>
      <!-- end LYC -->
      <br>
      <br>
      <br>
    </div>
  </div>
</template>

<script>

//import { InfoAreas } from "@/components";
import Arrow from "@/views/components/Arrow";
import Futurecomp from "@/views/components/Futurecomp";
import Loopdiagram from "@/views/components/Loopdiagram"

export default {
  name: "Features",
  components: {
    //InfoAreas,
    Arrow,
    Futurecomp,
    Loopdiagram
  },
  props: {
    sectionCardPlain: Boolean,
    sectionMorphingCards: Boolean,
  },
  data() {
    return {
      iphone: require("@/assets/img/sections/iphone.png"),
      iphone2: require("@/assets/img/sections/iphone2.png"),
      image: require("@/assets/img/bg9.jpg")
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`
      };
    }
  }
};
</script>

<style lang="scss" scoped>

.lcheader{
  color: #4DBD94 !important;
}

.lctext{
  font-size: 20px !important;
  font-family: "Karla", Helvetica, Arial, sans-serif;
}

.lcdsec {
  text-align: right;
}

.lc1{
  color: #4DBD94 !important;
}

.lc2{
  color: #23BCBA !important;
}

.lc3{
  color: #EF4266 !important;
}

#video1 {
  border-radius: 5px;
}

// XSmall devices (landscape phones, 576px and up)
@media (min-width: 300px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  #video1 {
    width: 100% !important;
  height: 500px;
  }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  #video1 {
    width: 100% !important;
  height: 600px;
  }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  #video1 {
    width: 100% !important;
  height: 600px;
  }
 }

</style>
