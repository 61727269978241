<template>
  <div class="wrapper">
    <!-- Features 1 -->
    <div class="section">    
        <div class="md-layout-item teamvid">
            <div class="container">
              <Loopteamstats />
            </div>
        </div>
          <Scrollama
            :debug="false"
            :offset="0.5"
            @step-enter="({ element }) => (currStep = element.dataset.stepNo)"
          >
            <div class="step" data-step-no="1">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    We believe that impact starts within each individual and organization.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="2">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    Throughout these first ten years we have never steered away from difficult conversations and challenges in the workplace.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="3">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    Everyone who has been part of our team has played a significant role in creating the culture that supports our efforts.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="4">
            </div>
          </Scrollama>
    </div>
    <!-- end Features 1 -->
  </div>
</template>

<script>
import "intersection-observer";
import Scrollama from "vue-scrollama";
import Loopteamstats from "@/views/components/Loopteamstats";

export default {
  name: "Features",
  components: {
    Scrollama,
    Loopteamstats
  },
  data() {
    return {
      currStep: null,
      iphone: require("@/assets/img/sections/iphone.png"),
      image: require("@/assets/img/bg9.jpg")
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`,
        iphone: require("@/assets/img/sections/iphone.png"),
      };
    }
  }
};
</script>




<style lang="scss">

.teamvid {
  position: sticky;
  top: 0;
  height: 100vh;
  //display: flex;
  align-items: center;
  justify-content: center;
  z-index: -7;
  align-items: center;
  
}

.step {
  padding: 15vh 0;
  width: 50%;
  margin: 0 auto 30vh;
  background-color: #00000000;
  border: 1px solid #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  
}





</style>
