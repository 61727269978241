<template>
  <div class="wrapper">
    <div class="main main-raised">
      <!-- Lead -->
      <div class="section">
        <div class="container">
          <div class="md-layout"
               data-aos="fade-up"
               data-aos-easing="mk-easing"
               data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <h2 class="title text-center lcheader">
                Helping companies lead the way
              </h2>
            </div>
          </div>
          <div class="md-layout-item md-size-10 md-small-size-20 mx-auto text-center"
               data-aos="fade-up"
               data-aos-easing="mk-easing"
               data-aos-duration="750">
            <Arrow/>
          </div>
          <div class="md-layout"
               data-aos="fade-up"
               data-aos-easing="mk-easing"
               data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <p class="lctext">
                During the last 10 years, <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity</a></b>
                has devoted its efforts to empowering individuals and organizations to become their best selves. As an
                impact management firm, we certainly do not have all the answers. But during our journey we have made a
                name for ourselves in defining, measuring, validating, and marketing impact in a world that is
                increasingly concerned with transparency and accountability.
              </p>
              <br>
              <br>

            </div>
          </div>
          <div class="md-layout"
               data-aos="fade-up"
               data-aos-easing="mk-easing"
               data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <div class="title">
                <h3 class="title text-center lcheader">Learn more about how <a href="https://mylifecity.com/"
                                                                               target="_blank" class="sub">LifeCity</a>
                  can help</h3>
              </div>
              <tabs
                  pills-align="center"
                  :tab-active="1"
                  :tab-name="['Investors', 'Manufacturers', 'Corporations']"
                  plain
                  color-button="success"
              >
                <!-- here you can add your content for tab-content -->
                <template slot="tab-pane-1">
                  <br>
                  <div class="iframe-container"
                       data-aos="fade-up"
                       data-aos-easing="mk-easing"
                       data-aos-duration="750">
                    <iframe id="video1" src="https://www.youtube.com/embed/-B346PqfMC0" title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                </template>
                <template slot="tab-pane-2">
                  <br>
                  <div class="iframe-container"
                       data-aos="fade-up"
                       data-aos-easing="mk-easing"
                       data-aos-duration="750">
                    <iframe id="video1" src="https://www.youtube.com/embed/MXgevnUQSDw" title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                </template>
                <template slot="tab-pane-3">
                  <br>
                  <div class="iframe-container"
                       data-aos="fade-up"
                       data-aos-easing="mk-easing"
                       data-aos-duration="750">
                    <iframe id="video1" src="https://www.youtube.com/embed/JiG5kJpXrHI" title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                </template>
              </tabs>
            </div>
          </div>
        </div>
        <br>
        <br>
        <br>
        <br>
        <!-- end Lead -->
        <!-- Collapse Lead Dash -->
        <div class="container">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
               data-aos="fade-up"
               data-aos-easing="mk-easing"
               data-aos-duration="750">
            <h3 class="title text-center lcheader">
              Explore our impact
            </h3>
            <br>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 mx-auto text-center">
              <!--
              <collapse
                :active-tab="0"
                :collapse="[
                  'Explore our impact'
                ]"
                icon="keyboard_arrow_down"
                color-collapse="success"
              >
                <template slot="md-collapse-pane-1">
                  -->
              <div id="leadcards">
                <!-- Morphing Cards -->
                <!-- Top Line -->
                <div class="md-layout"
                     data-aos="fade-right"
                     data-aos-easing="mk-easing"
                     data-aos-duration="750">
                  <!--Card1-->
                  <div
                      class="md-layout-item md-large-size-33 md-medium-size-33 md-small-size-100"
                  >
                    <rotating-card
                        :manual-rotate-state="manualRotateState1"
                        manual-rotate
                    >
                      <template slot="frontRotateCardContent">
                        <h5 class="card-category card-category-social text-success">
                          <i class="fas fa-chart-bar"></i><br> Reporting

                        </h5>
                        <h4 class="card-title">
                          788 Place-based Impact Reports
                        </h4>
                        <Assessments/>
                        <!--
                        <p class="card-description">
                          These reports target business and investor needs of all sorts, and include in-depth
                          analysis of place-based social and environmental challenges.
                        </p>
                        -->
                        <div class="stats text-center">
                          <md-button
                              href="javascript:void(0)"
                              class="md-success md-round"
                              @click="manualRotateState1 = true"
                          >
                            <md-icon>refresh</md-icon>
                            Rotate...
                          </md-button>
                        </div>
                      </template>
                      <template slot="backRotateCardContent">
                        <h5 class="card-title">
                          70 Impact Reports per year
                        </h5>
                        <!--
                        <p class="card-description">
                          Our annual production responds to current market needs.
                          Our comprehensive reports favor transparency and implementation.
                        </p>
                        -->
                        <Assessmentstime/>
                        <div class="stats text-center">
                          <br>
                          <md-button
                              href="javascript:void(0)"
                              class="md-rose md-round"
                              @click="manualRotateState1 = false"
                          >
                            <md-icon>refresh</md-icon>
                            Back
                          </md-button>
                        </div>
                      </template>
                    </rotating-card>
                  </div>
                  <!--Card2-->
                  <div
                      class="md-layout-item md-large-size-33 md-medium-size-33 md-small-size-50"
                  >
                    <rotating-card
                        :manual-rotate-state="manualRotateState2"
                        manual-rotate
                    >
                      <template slot="frontRotateCardContent">
                        <h5 class="card-category card-category-social text-success">
                          <i class="fas fa-wrench"/><br>Implementation
                        </h5>
                        <h4 class="card-title">
                          235 Impact Opportunities
                        </h4>
                        <Opportunities/>
                        <!--
                        <p class="card-description">
                          Despite opportunities are rarely fully implemented, they constitute the perfect
                          starting point for organizations to increase their social and
                          environmental impact.
                        </p>
                        -->
                        <div class="stats text-center">
                          <md-button
                              href="javascript:void(0)"
                              class="md-success md-round"
                              @click="manualRotateState2 = true"
                          >
                            <md-icon>refresh</md-icon>
                            Rotate...
                          </md-button>
                        </div>
                      </template>
                      <template slot="backRotateCardContent">
                        <h5 class="card-title">
                          Implementation Rate: 21%
                        </h5>
                        <!--
                        <p class="card-description">
                          Our Assessment and Consulting Reports always always favor implementation
                          by focusing on feasible opportunities for Companies to effectively meet
                          the needs of their communities.
                        </p>
                        -->
                        <Opportunitiestime/>
                        <div class="stats text-center">
                          <br>
                          <md-button
                              href="javascript:void(0)"
                              class="md-rose md-round"
                              @click="manualRotateState2 = false"
                          >
                            <md-icon>refresh</md-icon>
                            Back
                          </md-button>
                        </div>
                      </template>
                    </rotating-card>
                  </div>
                  <!--Card3-->
                  <div
                      class="md-layout-item md-large-size-33 md-medium-size-33 md-small-size-50"
                  >
                    <rotating-card
                        :manual-rotate-state="manualRotateState4"
                        manual-rotate
                    >
                      <template slot="frontRotateCardContent">
                        <h5 class="card-category card-category-social text-success">
                          <i class="fas fa-chart-line"></i>
                          <br>Savings
                        </h5>
                        <h4 class="card-title">
                          7M pounds of CO2e per year
                        </h4>
                        <Carbon/>
                        <!--
                        <p class="card-description">
                          This reduction responds to companies' efforts to address Climate Change
                          and adamant stakeholders demanding immediate action.
                        </p>
                        -->
                        <div class="stats text-center">
                          <md-button
                              href="javascript:void(0)"
                              class="md-success md-round"
                              @click="manualRotateState4 = true"
                          >
                            <md-icon>refresh</md-icon>
                            Rotate...
                          </md-button>
                        </div>
                      </template>
                      <template slot="backRotateCardContent">
                        <h5 class="card-title">
                          Success Rate: 26%
                        </h5>
                        <!--
                        <p class="card-description">
                          It is equivalent to the carbon sequestered by 398 acres of U.S.
                          forests during the last 10 years.
                        </p>
                        -->
                        <Carbontime/>
                        <div class="stats text-center">
                          <br>
                          <md-button
                              href="javascript:void(0)"
                              class="md-rose md-round"
                              @click="manualRotateState4 = false"
                          >
                            <md-icon>refresh</md-icon>
                            Back
                          </md-button>
                        </div>
                      </template>
                    </rotating-card>
                  </div>
                </div>
                <!-- Bottom Line -->
                <div class="md-layout"
                     data-aos="fade-left"
                     data-aos-easing="mk-easing"
                     data-aos-duration="750">
                  <!--Card4-->
                  <div
                      class="md-layout-item md-large-size-33 md-medium-size-33 md-small-size-50"
                  >
                    <rotating-card
                        :manual-rotate-state="manualRotateState6"
                        manual-rotate
                    >
                      <template slot="frontRotateCardContent">
                        <h5 class="card-category card-category-social text-success">
                          <i class="fas fa-book"></i><br>Education
                        </h5>
                        <h4 class="card-title">
                          10,059 individuals
                        </h4>
                        <Events/>
                        <!--
                        <p class="card-description">
                          Seminars, Forums, Networking Spaces, and Celebrations have all been
                          used to educate ourselves and our network.
                        </p>
                        -->
                        <div class="stats text-center">
                          <md-button
                              href="javascript:void(0)"
                              class="md-success md-round"
                              @click="manualRotateState6 = true"
                          >
                            <md-icon>refresh</md-icon>
                            Rotate...
                          </md-button>
                        </div>
                      </template>
                      <template slot="backRotateCardContent">
                        <h5 class="card-title">
                          144 Events (34% education seminars)
                        </h5>
                        <!--
                        <p class="card-description">
                          As time has passed our events have decreased in number but increased in attendance.
                          During the last four years we have averaged 182 attendants per event.
                        </p>
                        -->
                        <Eventstime/>
                        <div class="stats text-center">
                          <br>
                          <md-button
                              href="javascript:void(0)"
                              class="md-rose md-round"
                              @click="manualRotateState6 = false"
                          >
                            <md-icon>refresh</md-icon>
                            Back
                          </md-button>
                        </div>
                      </template>
                    </rotating-card>
                  </div>
                  <!--Card5-->
                  <div
                      class="md-layout-item md-large-size-33 md-medium-size-33 md-small-size-50"
                  >
                    <rotating-card
                        :manual-rotate-state="manualRotateState5"
                        manual-rotate
                    >
                      <template slot="frontRotateCardContent">
                        <h5 class="card-category card-category-social text-success">
                          <i class="far fa-chart-bar"></i><br>Savings
                        </h5>
                        <h4 class="card-title">
                          4.5M KWh per year
                        </h4>
                        <Energy/>
                        <!--
                        <p class="card-description">
                          This reduction was attained through the implementation of multiple solutions
                          in commercial and manufacturing spaces.
                        </p>
                        -->
                        <div class="stats text-center">
                          <md-button
                              href="javascript:void(0)"
                              class="md-success md-round"
                              @click="manualRotateState5 = true"
                          >
                            <md-icon>refresh</md-icon>
                            Rotate...
                          </md-button>
                        </div>
                      </template>
                      <template slot="backRotateCardContent">
                        <h5 class="card-title">
                          Success Rate: 23%
                        </h5>
                        <!--
                        <p class="card-description">
                          It has an environmental impact of 1,189 MTCO2e
                          and is equivalent to the electricity use
                          of U.S. 58 homes during the past 10 years.
                        </p>
                        -->
                        <Energytime/>
                        <div class="stats text-center">
                          <br>
                          <md-button
                              href="javascript:void(0)"
                              class="md-rose md-round"
                              @click="manualRotateState5 = false"
                          >
                            <md-icon>refresh</md-icon>
                            Back
                          </md-button>
                        </div>
                      </template>
                    </rotating-card>
                  </div>
                  <!--Card6-->
                  <div
                      class="md-layout-item md-large-size-33 md-medium-size-33 md-small-size-100"
                  >
                    <rotating-card
                        :manual-rotate-state="manualRotateState3"
                        manual-rotate
                    >
                      <template slot="frontRotateCardContent">
                        <h5 class="card-category card-category-social text-success">
                          <i class="fas fa-chart-area"></i><br> Savings
                        </h5>
                        <h4 class="card-title">
                          17M pounds of solid waste per year
                        </h4>
                        <Solidwaste/>
                        <!--
                        <p class="card-description">
                          This reduction is aligned with growing market and community concerns around the social
                          and environmental effects of solid waste management.
                        </p>
                        -->
                        <div class="stats text-center">
                          <md-button
                              href="javascript:void(0)"
                              class="md-success md-round"
                              @click="manualRotateState3 = true"
                          >
                            <md-icon>refresh</md-icon>
                            Rotate...
                          </md-button>
                        </div>
                      </template>
                      <template slot="backRotateCardContent">
                        <h5 class="card-title">
                          Success Rate: 69%
                        </h5>
                        <!--
                        <p class="card-description">
                          This success rate translates to an annual reduction of 24,719 MTCO2e which is
                          equivalent to 136 railcars' worth of coal burned.
                        </p>
                        -->
                        <br>
                        <Solidwastetime/>
                        <div class="stats text-center">
                          <br>
                          <md-button
                              href="javascript:void(0)"
                              class="md-rose md-round"
                              @click="manualRotateState3 = false"
                          >
                            <md-icon>refresh</md-icon>
                            Back
                          </md-button>
                        </div>
                      </template>
                    </rotating-card>
                  </div>
                </div>
                <!-- end Morphing Cards -->
              </div>
              <!--
              </template>
            </collapse>
            -->
            </div>
          </div>
        </div>
      </div>
      <!-- Collapse Lead Dash -->
    </div>
  </div>
</template>

<script>
//import { Collapse } from "@/components";
import {RotatingCard, Tabs} from "@/components";
import Arrow from "@/views/components/Arrow";
import Assessments from "@/views/components/Assessments";
import Assessmentstime from "@/views/components/Assessmentstime";
import Events from "@/views/components/Events";
import Eventstime from "@/views/components/Eventstime";
import Energy from "@/views/components/Energy";
import Energytime from "@/views/components/Energytime";
import Carbon from "@/views/components/Carbon";
import Carbontime from "@/views/components/Carbontime";
import Solidwaste from "@/views/components/Solidwaste";
import Solidwastetime from "@/views/components/Solidwastetime";
import Opportunities from "@/views/components/Opportunities";
import Opportunitiestime from "@/views/components/Opportunitiestime";

export default {
  name: "Features",
  components: {
    //Collapse,
    RotatingCard,
    Tabs,
    Arrow,
    Assessments,
    Assessmentstime,
    Events,
    Eventstime,
    Energy,
    Energytime,
    Carbon,
    Carbontime,
    Opportunities,
    Opportunitiestime,
    Solidwaste,
    Solidwastetime
  },
  props: {
    sectionCardPlain: Boolean,
    sectionMorphingCards: Boolean,
  },
  data() {
    return {
      manualRotateState1: false,
      manualRotateState2: false,
      manualRotateState3: false,
      manualRotateState4: false,
      manualRotateState5: false,
      manualRotateState6: false
    };
  }
};
</script>

<style lang="scss" scoped>

.lcheader {
  color: #4DBD94 !important;
}

.lctext {
  font-size: 20px !important;
  font-family: "Karla", Helvetica, Arial, sans-serif;
}

#video1 {
  border-radius: 5px;
}

// XSmall devices (landscape phones, 576px and up)
@media (min-width: 300px) {
  #video1 {
    width: 100% !important;
    height: 400px;
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #video1 {
    width: 100% !important;
    height: 400px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  #video1 {
    width: 100% !important;
    height: 400px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #video1 {
    width: 100% !important;
    height: 500px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #video1 {
    width: 100% !important;
    height: 600px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  #video1 {
    width: 100% !important;
    height: 600px;
  }
}


.sub {
  color: #4DBD94 !important;
}

.sub h3 {
  color: inherit;
}

</style>
