<template>
  <div class="wrapper">
    <parallax
        class="section page-header header-filter"
        parallax-active="true"
        :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-25 md-small-size-35 mx-auto text-center">
            <a href="https://mylifecity.com/">
              <Lifecity/>
            </a>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-70 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              Our first 10 years.
            </h1>
            <h4>
              During the past 10 years we have learned to walk. Join us as we learn
              to fly. Through our place-based approach we have brought impact closer
              to our community by bringing everyone to the table. After all, the only
              way to solve our greatest challenges is through a collective-impact
              approach. We celebrate this milestone by setting goals to build a new
              system with our community - one that promotes the greater good - and
              you are all invited to join us.
            </h4>
            <br>
            <br>
            <br>
            <!--
            <md-button
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              class="md-success md-lg"
              target="_blank"
            >
              <i class="fas fa-play" /> Watch video
            </md-button>
            -->
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout"
               data-aos="fade-up"
               data-aos-easing="mk-easing"
               data-aos-duration="750">
            <div
                class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
            >
              <h2 class="title text-center lcheader">
                Letter from our CEO
              </h2>
            </div>
          </div>
          <div class="md-layout-item md-size-10 md-small-size-20 mx-auto text-center"
               data-aos="fade-up"
               data-aos-easing="mk-easing"
               data-aos-duration="750">
            <Arrow/>
            <br>
            <br>
          </div>

          <div class="md-layout"
               data-aos="fade-up"
               data-aos-easing="mk-easing"
               data-aos-duration="750">
            <div
                class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
            >
              <profile-card
                  card-plain
                  :shadow-normal="true"
                  :no-colored-shadow="false"
                  :card-avatar="true"
                  :card-image="cardProfile.cardProfile1"
              >
                r>
              </profile-card>
              <p class="lctext"
                 data-aos="fade-up"
                 data-aos-easing="mk-easing"
                 data-aos-duration="750">
                <br>
                <br>
                Dear Friends and Partners,
                <br>
                <br>
                If there is one thing I’ve learned over the past 10 years, it’s that no problem can be solved alone -
                not by one person or organization. Any great effort requires collective impact. We know this not just
                with work, but in life: our greatest moments of joy come from our shared relationships and experiences.
                <br>
                <br>
                What makes collective work and our collective joy possible is healthy, productive relationships.
                Therefore, our community should be the center of our economy. Especially coming out of this pandemic, if
                there is one thing we must focus on, it is what MLK calls our Beloved Community.
                <br>
                <br>
                Since I started <b><a href="https://mylifecity.com/" target="_blank"
                                      class="text-success">LifeCity</a></b> ten years ago, I’ve had MLK’s quote at the
                bottom of my email signature: "As long as there is poverty in the world, I can never be rich... I can
                never be what I ought to be until you are what you ought to be. This is the way our world is made. No
                individual or nation can stand out boasting of being independent. We are all interdependent." --Martin
                Luther King, Jr.
                <br>
                <br>
                This quote is still there, and the <b><a href="https://mylifecity.com/" target="_blank"
                                                         class="text-success">LifeCity</a></b> team is still with you -
                trying to figure out how to make the systems we operate within every day favor impact over profit
                (because this strategy IS what is ultimately, in the long-term, profitable).
                <br>
                <br>
                I’m grateful to say we’ve learned so much in past 10 years and are well-positioned to scale our
                knowledge across the country. As we work with impact investors, companies, and communities in designing
                their impact strategies, we are excited to see a world that is in transition from a hustling grind to a
                mindful community.
                <br>
                <br>
                And most importantly - we wouldn’t be here without you. Thank you. If there is ever any way we can serve
                you better, don’t hesitate to let us know.
                <br>
                <br>
                Together we can build a world we all want to live in — with a four-day workweek, living wages, and a
                cooling planet. Let’s talk impact.
                <br>
                <br>
                In gratitude,
                <br>
                Liz and the <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity</a></b>
                Team
              </p>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-33 md-small-size-55 mx-auto text-center">
              <Signature/>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {ProfileCard} from "@/components";
import Mixins from "@/plugins/basicMixins";
import Lifecity from "@/views/components/Lifecity";
import Arrow from "@/views/components/Arrow";
import Signature from "@/views/components/Signature";

export default {
  components: {
    ProfileCard,
    Lifecity,
    Arrow,
    Signature
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "landing-page",
  data() {
    return {
      image: require("@/assets/img/header/tree.jpg"),
      name: null,
      email: null,
      message: null,
      cardProfile: {
        cardProfile1: require("@/assets/img/ceo/liz.jpg")
      },
      cardProfile3: {
        cardProfile1: require("@/assets/img/faces/card-profile1-square.jpg"),
        cardProfile2: require("@/assets/img/faces/card-profile6-square.jpg"),
        cardProfile3: require("@/assets/img/faces/card-profile4-square.jpg"),
        cardProfile4: require("@/assets/img/faces/card-profile2-square.jpg")
      }
    };
  }
};
</script>

<style lang="scss" scoped>

.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}

.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}

.lcheader {
  color: #4DBD94 !important;
}

.lctext {
  font-size: 20px !important;
  font-family: "Karla", Helvetica, Arial, sans-serif;
}

</style>
