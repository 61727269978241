<template>
  <!-- Big White Footer V2 -->
      <footer class="footer footer-white footer-big">
        <div class="container">
          <ul class="social-buttons">
            <li>
              <md-button
                href="https://twitter.com/mylifecity"
                target="_blank"
                class="md-twitter md-just-icon md-simple"
              >
                <i class="fab fa-twitter" />
              </md-button>
            </li>
            <li>
              <md-button
                href="https://www.linkedin.com/company/lifecity-llc"
                target="_blank"
                class="md-linkedin md-just-icon md-simple"
              >
                <i class="fab fa-linkedin" />
              </md-button>
            </li>
            <li>
              <md-button
                href="https://www.youtube.com/user/LifeCities"
                target="_blank"
                class="md-youtube md-just-icon md-simple"
              >
                <i class="fab fa-youtube" />
              </md-button>
            </li>
          </ul>

          <div class="md-layout-item">
            Copyright © {{ year }} <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity, L3C</a></b>, a for-benefit company.
          </div>
        </div>
      </footer>
      <!-- end Big White Footer V2 -->
  
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  }
};
</script>
<style>
.lcxfooter{
  text-color: #4DBD94 !important;
}

</style>
