<template>
  <div class="wrapper">
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
            >
              <h2 class="title text-center lcheader">
                A new kind of workplace
              </h2>
            </div>
          </div>
          <div class="md-layout-item md-size-10 md-small-size-20 mx-auto text-center"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <Arrow/>
          </div>
          <div class="md-layout"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <p class="lctext">
              <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity</a></b> has evolved into a diverse, team-centered workplace that aims to be on the cutting edge of high-impact and high-thriving work arrangements. <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity's</a></b> benefits have expanded along with its business, creating an environment in which personal and professional growth of team members is valued alongside company growth. LifeCity team members have access to:
              <br>
              <br>
              · flexible time / unlimited PTO and paid holidays<br>
              · home-buying assistance<br>
              · bike-to-work incentives<br>
              · 50% paid health insurance, 100% vision and dental<br>
              · mental health reimbursements<br>
              · 3% retirement match<br>
              · 4-day work week.<br>
              <br>
              <br>
              <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity</a></b> will continue exploring new opportunities to create impact in a way that maximizes the physical and emotional health of its team members and community. Tell us what you think!
              </p>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="container">
          <div class="md-layout"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <div class="iframe-container">
                <iframe id="video1" src="https://www.youtube.com/embed/mwMJiOWqWpU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <br>
        <!-- Team Cards -->
        <div class="container">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <h3 class="title text-center lcheader">
              What’s it like to be part of our team?
            </h3>
            <br>
          </div>
          <div class="md-layout"
              data-aos="fade-right"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="thumbnail md-layout-item md-size-50 md-small-size-100">
              <blog-card
                class="picture1"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg1" 
              >
                <template slot="cardContent">
                  <h6 class="card-category text-danger">
                    Liz Shephard
                  </h6>
                  <p class="card-title lctext">
                    "When I started LifeCity, I spent a lot of time looking into trash bins and tabling at Farmers Markets in New Orleans."
                  </p>
                  <!--
                  <p class="card-description">
                    Don't be scared of the truth because we need to restart the
                    human foundation in truth And I love you like Kanye loves
                    Kanye I love Rick Owens’ bed design but the back is...
                    <a href="javascript:void(0)">Read More</a>
                  </p>
                  -->
                </template>
              </blog-card>
              <blog-card
                class="picture2"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg2" 
              >
                <template slot="cardContent">
                  <h6 class="card-category text-danger">
                    Liz Shephard
                  </h6>
                  <p class="card-title lctext">
                    "Now, I'm proud to say we provide software tools and state-of-the-art impact reporting for companies and investors nationwide...but I'm still dumpster diving!"
                  </p>
                  <!--
                  <p class="card-description">
                    Don't be scared of the truth because we need to restart the
                    human foundation in truth And I love you like Kanye loves
                    Kanye I love Rick Owens’ bed design but the back is...
                    <a href="javascript:void(0)">Read More</a>
                  </p>
                  -->
                </template>
              </blog-card>
            </div>
            <div class="thumbnail md-layout-item md-size-50 md-small-size-100">
              <blog-card
                class="picture1"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg3"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-primary">
                    Durell Barnes
                  </h6>
                  <p class="card-title lctext">
                    "Working for LifeCity in New Orleans is like looking through a kaleidoscope and seeing the future. Bridging together culture, sustainability, and evolution so colorfully makes it all feel right as less like work at all."
                  </p>
                </template>
              </blog-card>
              <blog-card
                class="picture2"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg4"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-primary">
                    Durell Barnes
                  </h6>
                  <p class="card-title lctext">
                    "I enjoy it because it allows you to step outside of yourself for a second, and think about how you can make your life better, those around you, and even for those that will be around after you."
                  </p>
                </template>
              </blog-card>
            </div>
          </div>
          <br>
          <div class="md-layout"
              data-aos="fade-left"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="thumbnail md-layout-item md-size-50 md-small-size-100">
              <blog-card
                class="picture1"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg5"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-success">
                    Tana Velen
                  </h6>
                  <p class="card-title lctext">
                    "In the three months I've been here, I've felt immensely supported. Working here means working with a tight, driven team who truly believe that a rising tide lifts all boats."
                  </p>
                </template>
              </blog-card>
              <blog-card
                class="picture2"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg6"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-success">
                    Tana Velen
                  </h6>
                  <p class="card-title lctext">
                    "I enjoy that the mission helps me meet the people behind impact. The faces of businesses that, whether or not impact is directly related to their mission statement, choose to prioritize it. They all have the same things in common: authenticity, passion, and a deep connection to our city. Meeting them inspires me and I'm grateful for the opportunity."
                  </p>
                </template>
              </blog-card>
            </div>
            <div class="thumbnail md-layout-item md-size-50 md-small-size-100">
              <blog-card
                class="picture1"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg7"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-warning">
                    Jourdan Bazley
                  </h6>
                  <p class="card-title lctext">
                    "Working for Life City is inspiring and heartwarming. Not only do I get to challenge myself while working alongside supportive and hardworking teammates, but I get to work for a company whose mission is to do good, everyday. This mission is felt throughout the work, products and team."
                  </p>
                </template>
              </blog-card>
              <blog-card
                class="picture2"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg8"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-warning">
                    Jourdan Bazley
                  </h6>
                  <p class="card-title lctext">
                    "I've always wanted to find the perfect work space to balance my career growth as well as personal growth. I was blessed to be able to find that with LifeCity. I love challenges, love to learn and love to stay connected with the community, so having a workplace that does just that everyday, all while being a space full of real, compassionate and strong teammates is a mission I'll take on any day."
                  </p>
                </template>
              </blog-card>
            </div>
          </div>
          <br>
          <div class="md-layout"
              data-aos="fade-right"
              data-aos-easing="mk-easing"
              data-aos-duration="750"> 
            <div class="thumbnail md-layout-item md-size-50 md-small-size-100">
              <blog-card
                class="picture1"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg9"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-success">
                    Jake Lipsman 
                  </h6>
                  <p class="card-title lctext">
                    "Working for LifeCity has been a tremendously positive experience. Having a team that is so close-knit and motivated makes working at LifeCity exciting and engaging."
                  </p>
                </template>
              </blog-card>
              <blog-card
                class="picture2"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg10"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-success">
                    Jake Lipsman 
                  </h6>
                  <p class="card-title lctext">
                    "Being part of an organization working to build an economy centered on equity and sustainability allows me to put my own values to work every day. It is exciting to be part of a team working toward this mission."
                  </p>
                </template>
              </blog-card>
            </div>
            <div class="thumbnail md-layout-item md-size-50 md-small-size-100">
              <blog-card
                class="picture1"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg11"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-info">
                    Andres Peña
                  </h6>
                  <p class="card-title lctext">
                    "I love working at LifeCity. The company always puts the individual first. I know I can bring myself to work every day and always be welcome. It means a lot because we transmit that honesty, transparency, and diversity through everything we do."
                  </p>
                </template>
              </blog-card>
              <blog-card
                class="picture2"
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="dynamicShadows.dynamicShadowsBg12"
              >
                <template slot="cardContent">
                  <h6 class="card-category text-info">
                    Andres Peña
                  </h6>
                  <p class="card-title lctext">
                    "I enjoy waking up every morning and feeling that I am making a dent in this planet. I know it is a small one. But still, imagine what the world would look like if we were intentional about making the right kinds of dents on a daily basis."
                  </p>
                </template>
              </blog-card>
            </div>  
          </div>
          <br>
          <br>
        </div>
        <!-- Ends Team Cards -->
      </div>
    </div>
  </div>
</template>

<script>
import { BlogCard } from "@/components";
import Arrow from "@/views/components/Arrow";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    BlogCard,
    Arrow
  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      subscribe: null,
      dynamicShadows: {
        dynamicShadowsBg1: require("@/assets/img/team/liz.jpg"),
        dynamicShadowsBg2: require("@/assets/img/team/liz2.jpg"),
        dynamicShadowsBg3: require("@/assets/img/team/durell.jpg"),
        dynamicShadowsBg4: require("@/assets/img/team/durell2.jpg"),
        dynamicShadowsBg5: require("@/assets/img/team/tana.jpg"),
        dynamicShadowsBg6: require("@/assets/img/team/tana2.jpg"),
        dynamicShadowsBg7: require("@/assets/img/team/jourdan.jpg"),
        dynamicShadowsBg8: require("@/assets/img/team/jourdan2.jpg"),
        dynamicShadowsBg9: require("@/assets/img/team/jake.jpg"),
        dynamicShadowsBg10: require("@/assets/img/team/jake2.jpg"),
        dynamicShadowsBg11: require("@/assets/img/team/andres.jpg"),
        dynamicShadowsBg12: require("@/assets/img/team/andres2.jpg")
      }
    };
  }
};
</script>

<style lang="scss" scoped>

#video1 {
  border-radius: 5px;
}

// XSmall devices (landscape phones, 576px and up)
@media (min-width: 300px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  #video1 {
    width: 100% !important;
  height: 500px;
  }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  #video1 {
    width: 100% !important;
  height: 600px;
  }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  #video1 {
    width: 100% !important;
  height: 600px;
  }
 }
 

 .lcheader{
  color: #4DBD94 !important;
}

.lctext{
  font-size: 20px !important;
  font-family: "Karla", Helvetica, Arial, sans-serif;
}


.picture2 {
    display: none;
}
.thumbnail:hover .picture1 {
    display: none;
}
.thumbnail:hover .picture2 {
    display: inline-block;

}


 </style>
