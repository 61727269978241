<template>
  <div class="wrapper">
    <!-- Features 1 -->
    <div class="section">    
        <div class="md-layout-item bigvid">
          <div class="container">
            <Loopbigstats />
          </div>
        </div>
          <Scrollama
            :debug="false"
            :offset="0.5"
            @step-enter="({ element }) => (currStep = element.dataset.stepNo)"
          >
            <div class="step" data-step-no="1">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    <a href="https://mylifecity.com/" target="_blank">LifeCity</a> was incorporated in 2011 as a solution to connect siloed recovery efforts in New Orleans post-Katrina.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="2">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    We partnered with the <a href="https://www.neworleanschamber.org/" target="_blank">New Orleans Chamber of Commerce</a> to bring a free impact assessment to companies in New Orleans.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="3">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    Our approach sought to leverage the city's rich culture and history; early on we recognized the power of local collaboration to build a better future.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="4">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    This is the story of our first 10 years and our unquenchable desire to build an economy that works for Good.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="5">
            </div>
          </Scrollama>
    </div>
    <!-- end Features 1 -->
  </div>
</template>

<script>
import "intersection-observer";
import Scrollama from "vue-scrollama";
import Loopbigstats from "@/views/components/Loopbigstats";

export default {
  name: "Features",
  components: {
    Scrollama,
    Loopbigstats
  },
  data() {
    return {
      currStep: null
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`,
        iphone: require("@/assets/img/sections/iphone.png"),
      };
    }
  }
};
</script>




<style lang="scss">

.bigvid {
  position: sticky;
  top: 0;
  height: 100vh;
  //display: flex;
  align-items: center;
  justify-content: center;
  z-index: -7;
  
}

.step {
  padding: 15vh 0;
  width: 50%;
  margin: 0 auto 30vh;
  background-color: #00000000;
  border: 1px solid #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

a:link {
  color: #4DBD94 !important;
}

</style>
