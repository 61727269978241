<template>
  <div class="wrapper">
    <!-- Features 1 -->
    <div class="section">    
        <div class="md-layout-item futurevid">
          <div class="container">
            <Loopfuture/>
          </div>
        </div>
          <Scrollama
            :debug="false"
            :offset="0.5"
            @step-enter="({ element }) => (currStep = element.dataset.stepNo)"
          >
            <div class="step" data-step-no="1">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    None of our efforts would be possible without great partnerships. We are only as strong as our community.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="2">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                  <h3 class="title">
                    We want to thank the <a href="https://www.neworleanschamber.org/" target="_blank">New Orleans Chamber of Commerce</a>, the <a href="https://www.gnof.org/" target="_blank">Greater New Orleans Foundation</a>, the <a href="https://www.marriott.com/en-us/hotels/msyis-sheraton-new-orleans-hotel/overview/" target="_blank">Sheraton New Orleans Hotel</a>, and the <a href="https://www.loveyourcitynola.com/" target="_blank">Regional Sustainability Committee</a> for your continued partnership and support.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="3">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                 <h3 class="title">
                    We look to the future with excitement. We know exactly what is going to happen.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="4">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                 <h3 class="title">
                    We will come together to make impact a priority, and New Orleans will stand out as a leader in resilience, equity, and sustainability.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="5">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                 <h3 class="title">
                    We want to be a part of your journey and help you maximize all the good you can do. Let's join forces!
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="6">
            </div>
          </Scrollama>
    </div>
    <!-- end Features 1 -->
  </div>
</template>

<script>
import "intersection-observer";
import Scrollama from "vue-scrollama";
import Loopfuture from "@/views/components/Loopfuture";

export default {
  name: "Features",
  components: {
    Scrollama,
    Loopfuture
  },
  data() {
    return {
      currStep: null,
      iphone: require("@/assets/img/sections/iphone.png"),
      image: require("@/assets/img/bg9.jpg")
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`,
        iphone: require("@/assets/img/sections/iphone.png"),
      };
    }
  }
};
</script>




<style lang="scss">

.futurevid {
  position: sticky;
  top: 0;
  height: 100vh;
  //display: flex;
  align-items: center;
  justify-content: center;
  z-index: -7;
  
}

.step {
  padding: 15vh 0;
  width: 50%;
  margin: 0 auto 30vh;
  background-color: #00000000;
  border: 1px solid #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  
}


</style>
