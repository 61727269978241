<template>
  <div class="wrapper">
    <div class="main main-raised" id="greenback">
      <div class="section section-features-1 pb-0">
        <div class="container">
          <div class="md-layout" 
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <h2 class="title text-center lcheader">
                Our History
              </h2>
            </div>
          </div>
          <div class="md-layout-item md-size-10 md-small-size-20 mx-auto text-center"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <Arrow/>
          </div>
          <div class="md-layout"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <p class="lctext">
                <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity</a></b> is proud to be approaching 10 years of stewarding place-based 
                impact in New Orleans and in distressed communities across the United 
                States. From the Green Card to the <b><a href="https://loveyourcity.us/" target="_blank" class="text-success">Love Your City™</a></b> platform, <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity</a></b> 
                has spent a decade creatively working toward maximizing impact for its 
                community and team.
              </p>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="container">
          <div class="md-layout"
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
              <div class="iframe-container">
                <iframe id="video1" src="https://www.youtube.com/embed/CSlzJJIyoRY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <br>
        <br>
        <br>
        <!-- Timeline -->  
        <div class="container">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center" 
              data-aos="fade-up"
              data-aos-easing="mk-easing"
              data-aos-duration="750">
            <h3 class="title text-center lcheader">
              Timeline
            </h3>
            <br>
          </div> 
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 mx-auto text-center">      
              <!--
              <collapse
                :active-tab="0"
                :collapse="[
                  'Timeline'
                ]"
                icon="keyboard_arrow_down"
                color-collapse="success"
              >
                <template slot="md-collapse-pane-1">
                  -->
                  <div class="md-layout">
                    <div class="md-layout-item md-small-size-100">
                      <div class="md-layout">
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard1"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2011
                              </h4>
                              <p class="card-description lctext">
                                Village Capital Pitch Winner, Idea Village. <b><a href="https://mylifecity.com/" target="_blank" class="text-success">LifeCity</a></b> became incorporated.
                              </p>
                            </template>      
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard2"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2012
                              </h4>
                              <p class="card-description lctext">
                                First Sustainable Business Awards Ceremony “The Green Natties” in partnership with Natural Awakenings Magazine and the <b><a href="https://www.neworleanschamber.org/" target="_blank" class="text-success">New Orleans Chamber of Commerce</a></b>. First “Green the Gras” Mardi Gras Competition.
                              </p>
                            </template>
                            <!--
                            <template slot="cardAction">
                              <div class="price-container">
                                <span class="price">€555</span>
                              </div>
                              <div class="stats ml-auto">
                                <md-button
                                  class="md-just-icon md-rose md-round md-simple"
                                >
                                  <md-icon>favorite_border</md-icon>
                                  <md-tooltip
                                    md-direction="left"
                                  >
                                    Save to Wishlist
                                  </md-tooltip>
                                </md-button>
                              </div>
                            </template>
                            -->
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard3"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2013
                              </h4>
                              <p class="card-description lctext">
                                First NMTC Impact Report + No-sound video, Fourth Sector Development seed funding to launch what would become the Regional Sustainability Committee. The <b><a href="https://www.gnof.org/" target="_blank" class="text-success">Greater New Orleans Foundation</a></b> began investing in our work with a small grant.
                              </p>
                            </template>
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard4"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2014
                              </h4>
                              <p class="card-description lctext">
                                First Impact Video (complete with sound!), first contract with the <b><a href="https://www.epa.gov/" target="_blank" class="text-success">EPA</a></b>.
                              </p>
                            </template>
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard5"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2015
                              </h4>
                              <p class="card-description lctext">
                                <b><a href="https://originnocb.wpengine.com/blog/2015/07/08/designation-smooths-funding-access-for-chemical-corridor/" target="_blank" class="text-success">IMCP Designation</a></b> Won for the State of Louisiana, attracting millions of dollars to our community, including the Industrial Assessment Center for LSU.
                              </p>
                            </template>
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard6"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2016
                              </h4>
                              <p class="card-description lctext">
                                First software prototype developed, logo for the Green Games established for 5-year anniversary.
                              </p>
                            </template>
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard7"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2017
                              </h4>
                              <p class="card-description lctext">
                                Created the Environmental Advisory Committee for the City of New Orleans, supporting policies that shape business practices. Won the Sustainable Business Champion award for Louisiana.
                              </p>
                            </template>
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard8"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2018
                              </h4>
                              <p class="card-description lctext">
                                Rebranded to the <b><a href="https://loveyourcityawards.com/" target="_blank" class="text-success">Love Your City™ Awards</a></b>, 
                                Impact Management System services established, DBE certification. 
                              </p>
                            </template>
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard9"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                              2019
                              </h4>
                              <p class="card-description lctext">
                              Grew our national clients and won our largest contract for impact investors.
                              </p>
                            </template>
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard10"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2020
                              </h4>
                              <p class="card-description lctext">
                                Navigated a pandemic with a growing team and pivoted the Awards to help feed 500 families in need with healthy fresh food for 1 week.
                              </p>
                            </template>
                            <!--
                            <template slot="cardAction">
                              <div class="price-container">
                                <span class="price">€555</span>
                              </div>
                              <div class="stats ml-auto">
                                <md-button
                                  class="md-just-icon md-rose md-round md-simple"
                                >
                                  <md-icon>favorite_border</md-icon>
                                  <md-tooltip
                                    md-direction="left"
                                  >
                                    Save to Wishlist
                                  </md-tooltip>
                                </md-button>
                              </div>
                            </template>
                            -->
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard11"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2021
                              </h4>
                              <p class="card-description lctext">
                                Introduced the new <b><a href="https://loveyourcity.us/" target="_blank" class="text-success">Love Your City™</a></b> software
                                platform, where our services could finally be captured with a database accessible to us and our clients.
                              </p>
                            </template>
                          </product-card>
                        </div>
                        <div class="md-layout-item md-size-25 md-small-size-50">
                          <product-card
                            text-center
                            class="mt-3"
                            card-plain
                            :card-image="productCard.productCard12"
                            :shadow-off="false"
                            :no-colored-shadow="false"
                          >
                            <template slot="cardContent">
                              <h4 class="card-title">
                                2022
                              </h4>
                              <p class="card-description lctext">
                                First year of our second decade. Growing our team and developing new partnerships to support other communities with the <b><a href="https://loveyourcity.us/" target="_blank" class="text-success">Love Your City™</a></b> platform.
                              </p>
                            </template>
                          </product-card>
                        </div>
                      </div>
                    </div>            
                  </div>
                <!--
                </template>
              </collapse>
              -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Timeline -->
      <br>
    </div>
  </div>
</template>

<script>
import {
  ProductCard,
  //Collapse
} from "@/components";
import AOS from "aos";
import "aos/dist/aos.css";
import Arrow from "@/views/components/Arrow";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    ProductCard,
    //Collapse,
    Arrow

  },
  mixins: [Mixins.HeaderImage],
  data() {
    return {
      subscribe: null,
      sliders: {
        rangeSlider: [2011, 2022]
      },
      image: require("@/assets/img/examples/clark-street-merc.jpg"),
      image2: require("@/assets/img/examples/ecommerce-header.jpg"),
      productCard: {
        productCard1: require("@/assets/img/timeline/2011.jpg"),
        productCard2: require("@/assets/img/timeline/2012.jpg"),
        productCard3: require("@/assets/img/timeline/2013.jpg"),
        productCard4: require("@/assets/img/timeline/2014.jpg"),
        productCard5: require("@/assets/img/timeline/2015.jpg"),
        productCard6: require("@/assets/img/timeline/2016.jpg"),
        productCard7: require("@/assets/img/timeline/2017.jpg"),
        productCard8: require("@/assets/img/timeline/2018.jpg"),
        productCard9: require("@/assets/img/timeline/2019.gif"),
        productCard10: require("@/assets/img/timeline/2020.jpg"),
        productCard11: require("@/assets/img/timeline/2021.gif"),
        productCard12: require("@/assets/img/timeline/2022.jpg")
      }
    };
  },
  created() {
    AOS.init();
  },
  computed: {
    imageSubscribe() {
      return {
        backgroundImage: `url(${this.image2})`
      };
    }
  },
  methods: {
    newValue(e) {
      this.sliders.rangeSlider[0] = e[0];
      this.sliders.rangeSlider[1] = e[1];
    }
  }
};
</script>

<style lang="scss" scoped>

[data-aos] {
  body[data-aos-easing="mk-easing"] &,
  &[data-aos][data-aos-easing="mk-easing"] {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.mt-3 {
  margin-top: 1.875rem * 2;
}
.mb-0 {
  margin-bottom: 0;
}

#video1 {
  border-radius: 5px;
}

#greenback{
  //background-color: lighten(#000, 90.5%);
  //box-shadow: 0px 0px 5px 0 #4dbd94;
  //border: 2px solid rgba(0, 0, 0);
}

// XSmall devices (landscape phones, 576px and up)
@media (min-width: 300px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  #video1 {
    width: 100% !important;
  height: 400px;
  }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  #video1 {
    width: 100% !important;
  height: 500px;
  }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  #video1 {
    width: 100% !important;
  height: 600px;
  }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  #video1 {
    width: 100% !important;
  height: 600px;
  }
 }

 .lcheader{
  color: #4DBD94 !important;
}

.lctext{
  font-size: 20px !important;
  font-family: "Karla", Helvetica, Arial, sans-serif;
}

</style>
