<template>
  <div class="wrapper">
    <div
      id="main-panel"
      class="main"
    >
      <!-- Header Section -->
      <div
        id="Headers"
        class="cd-section"
      >
        <headers />
      </div>

      <!-- Bigstats -->
      <div
          id="Bigstats"
          class="cd-section"
        >
          <Bigstats />
          <Timeline />
        </div>
      
      <!-- Timeline -->
      <!--
      <div
          id="Timeline"
          class="cd-section"
        >
          <Timeline />
        </div>
      -->
      
      <!-- Development -->
      <!--
      <div
          id="Development"
          class="cd-section"
        >
          <Development />
        </div>
      -->

      <!-- Devdash -->
      <!--
      <div
          id="devdash-cards"
          class="cd-section section section-gray"
        >
          <div class="container">
             <DevCards />
          </div>
        </div>
      -->

      <!-- Teamstats -->
      <div
          id="Teamstats"
          class="cd-section"
        >
          <Teamstats />
          <tenyears />
        </div>

      <!-- Tenyears Section -->
      <!--
      <div
        id="Tenyears"
        class="cd-section"
      >
        <tenyears />
      </div>
      -->

      <!-- Leadloop -->
      <div
          id="Leadstats"
          class="cd-section"
        >
          <Leadstats />
          <Lead />
        </div>
      
      <!-- Lead -->
      <!--
      <div
          id="Lead"
          class="cd-section"
        >
          <Lead />
      </div>
      -->

      <!-- Futureloop -->
      <div
          id="Futurestats"
          class="cd-section"
        >
          <Futurestats />
          <future />
        </div>

      <!-- Future -->
      <!--
      <div
        id="future"
        class="cd-section"
      >
        <future />
      </div>
      -->
    </div>
    
    <nav
      id="cd-vertical-nav"
      class="vertical-nav-active"
    >
      <ul>
        <li>
          <a
            href="javascript:void(0)"
            data-number="1"
            @click="scrollToElement('Headers')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Introduction</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="2"
            @click="scrollToElement('Bigstats')"
          >
            <span class="cd-dot" />
            <span class="cd-label">History</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="3"
            @click="scrollToElement('Teamstats')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Workplace</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="4"
            @click="scrollToElement('Leadstats')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Impact</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="5"
            @click="scrollToElement('Futurestats')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Future</span>
          </a>
        </li>
      </ul>
    </nav>
    
    
  </div>
</template>

<script>
import Headers from "./sections/Headers";
import Bigstats from "./sections/Bigstats";
import Timeline from "./sections/Timeline";
//import Development from "./sections/Development";
//import DevCards from "./sections/DevDashboardSection";
import Teamstats from "./sections/Teamstats";
import Tenyears from "./sections/Tenyears";
import Leadstats from "./sections/Leadstats";
import Lead from "./sections/Lead";
import Futurestats from "./sections/Futurestats";
import Future from "./sections/Future";
//import Features from "./sections/Features";
//import Blogs from "./sections/Blogs";
//import Teams from "./sections/Teams";
//import Projects from "./sections/Projects";
//import Pricing from "./sections/Pricing";
//import Testimonials from "./sections/Testimonials";
//import ContactUs from "./sections/ContactUs";
import Mixins from "@/plugins/basicMixins";

export default {
  components: {
    Headers,
    Bigstats,
    Timeline,
    //Development,
    //DevCards,
    Teamstats,
    Tenyears,
    Leadstats,
    Lead,
    Futurestats,
    Future
    //Features,
    //Blogs,
    //Teams,
    //Projects,
    //Pricing,
    //Testimonials,
    //ContactUs
  },
  mixins: [Mixins.VerticalNav]
};
</script>

<style lang="scss">
.vertical-nav-active {
  display: block;
}

@media all and (max-width: 768px) {
  .vertical-nav-active {
    display: none;
  }
}

#devdash-cards {
  padding-top: 70px;
}

#morphing-cards {
  padding-top: 70px;
}
</style>
