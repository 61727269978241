// =========================================================
// * LifeCity - 10 year impact report
// =========================================================
//
// * Company Page: https://mylifecity.com/
//
// =========================================================
//
// * Coded by Andres Peña (andres.pena.paez@gmail.com) for LifeCity, using Vue Material Kit PRO template
//
// =========================================================
// * Vue Material Kit PRO - v1.3.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";

import MaterialKit from "./plugins/material-kit";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons';
import scrollama from "scrollama";
import VideoBackground from 'vue-responsive-video-background-player';

library.add(faUserSecret)
library.add(faFontAwesome)



Vue.config.productionTip = false;
Vue.component('video-background', VideoBackground);
Vue.use(MaterialKit);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
