<template>
  <div class="wrapper">
    <!-- Features 1 -->
    <div class="section">    
        <div class="md-layout-item leadvid">
          <div class="container">
            <Looplead/>
          </div>
        </div>
          <Scrollama
            :debug="false"
            :offset="0.5"
            @step-enter="({ element }) => (currStep = element.dataset.stepNo)"
          >
            <div class="step" data-step-no="1">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                   <h3 class="title">
                    Impact starts with you. How can you make a difference on your team? Your community? And your environment?
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="2">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                   <h3 class="title">
                    <a href="https://mylifecity.com/" target="_blank">LifeCity</a> has the privilege of guiding companies through a process of understanding how their unique role and position or services can make a difference in the world.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="3">
              <div class="md-layout">
                <div
                  class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
                >
                   <h3 class="title">
                    Every business can help build an economy that works for Good - but it starts with measuring impact. Thank you for trusting us to be your Impact Management Partner.
                  </h3>
                  <!--
                  <h5 class="description">
                    During our first 10 years we have fine tuned our place based methodology
                    stewarding social and environmental impacts through multiple sectors.
                  </h5>
                  -->
                </div>
              </div>
            </div>
            <div class="step" data-step-no="4">
            </div>
          </Scrollama>
    </div>
    <!-- end Features 1 -->
  </div>
</template>

<script>
import "intersection-observer";
import Scrollama from "vue-scrollama";
import Looplead from "@/views/components/Looplead";

export default {
  name: "Features",
  components: {
    Scrollama,
    Looplead
  },
  data() {
    return {
      currStep: null,
      iphone: require("@/assets/img/sections/iphone.png"),
      image: require("@/assets/img/bg9.jpg")
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`,
        iphone: require("@/assets/img/sections/iphone.png"),
      };
    }
  }
};
</script>




<style lang="scss">

.leadvid {
  position: sticky;
  top: 0;
  height: 100vh;
  //display: flex;
  align-items: center;
  justify-content: center;
  z-index: -7;
  
}

.step {
  padding: 15vh 0;
  width: 50%;
  margin: 0 auto 30vh;
  background-color: #00000000;
  border: 1px solid #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  
}


a:link {
  color: #4DBD94 !important;
}

//a:hover {
//  color: #4DBD94 !important;
//}

</style>
